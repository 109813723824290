<template>
    <div>
        <Head :title="'保全管理'" :out="'guide'" />
        <div class="warper-box">
            <van-grid :gutter="10" :column-num="2">
                <van-grid-item icon="logistics" text="车辆拖运" to="/PreserveTheSend" />
                <van-grid-item icon="comment-o" text="车辆列表" to="/BqList" />
                <van-grid-item icon="exchange" text="车辆离场" to="/CarOut" />
            </van-grid>
        </div>
    </div>
</template>

<script>
import Head from '@/components/Head'
    export default {
        components: {
            Head,
        },
    }
</script>

<style lang="scss" scoped>
.warper-box{
    margin-top: 50px;
    padding: 5px;
}
</style>